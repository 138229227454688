import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/DeepTech/MachineLearning/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/DeepTech/MachineLearning/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/DeepTech/MachineLearning/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/DeepTech/MachineLearning/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Machine Learning Solutions: AI & NLP Predictive Analytics"
        meta={[
          {
            name: 'keywords',
            content:
              'machine learning solutions, AI solutions, nlp solutions, custom machine learning models, nlp machine learning, machine learning solutions development, machine learning nlp, ai and machine learning solutions, natural language processing company, machine learning and ai solutions, machine learning predictive analytics, predictive analytics machine learning, custom machine learning solutions, machine learning in predictive analytics, natural language processing machine learning, machine learning natural language processing, natural language processing, machine learning solutions company',
          },
        ]}
        description={
          'Explore top machine learning solutions, including NLP and predictive analytics. Custom AI solutions tailored to your needs. Start innovating today!'
        }
      />

      <CommonMargin>
        <StyledSection
          title="Machine learning"
          variant="secondary"
          id="Machine learning"
          isMobile={isMd}
          withBreadCrumbs
        >
          <HeaderSection>
            In today’s data-driven world, machine learning is transforming how businesses operate, innovate, and
            compete. At Codahead, we design and implement cutting-edge machine learning solutions tailored to your
            needs. From predictive analytics to natural language processing, we help you harness the potential of AI to
            automate processes, make data-driven decisions, and gain a competitive edge. Let’s redefine what’s possible
            for your business with intelligent technology.
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
